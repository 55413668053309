<template>
  <div class="pb-40">
    <RequestChanges
      :offerId="offer.id"
      @action="closeSheet"
      :class="['pageSheet', requestSheetPosition]"
      @close="closeSheet"
    />
    <GalleryLightbox
      @closeLightbox="closeLightbox"
      @closeGallery="closeLightbox"
      v-if="showGalleryLightbox"
      :photos="photos"
    />
    <Navbar
      mode="solid"
      class="navbar"
      :show-btn-icon="true"
      :btn-link-path="backPath"
      :show-currency-picker="false"
    />
    <main class="pb-32">
      <section class="mt-12">
        <div class="section-container">
          <div class="wrapper">
            <h1 class="page-title">{{ $t("request.title") }}</h1>
            <Tab :tabItems="requestTabSections" />
          </div>
        </div>
        <PageLoader v-if="loading" />
        <template v-else>
          <TripOffer
            v-if="requestTabSections[0].isActive == true"
            @openLightBox="openLightBox"
          />
          <TripDetails v-if="requestTabSections[1].isActive == true" />
        </template>
      </section>
    </main>

    <div class="bottom-nav" v-if="showBottomNav && !loading">
      <div class="wrapper">
        <div class="btn-wrapper">
          <ButtonBasic
            :text="`${$t('offer.button_1')}`"
            class="w-calc-1/2"
            @click.native="openSheet"
          />
          <ButtonPrimary
            @click.native="
              $router.push('/payment/summary/?trip_id=' + `${tripDetails.id}`);
              calculateStartTime();
            "
            :text="`${$t('offer.button_2')}`"
            class="w-calc-1/2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBasic from "@/components/buttons/ButtonBasic";
import GalleryLightbox from "@/components/gallery/GalleryLightbox";
import RequestChanges from "@/components/sheets/RequestChanges";
import Tab from "@/components/tabs/Tab";
import TripOffer from "./TripOffer.vue";
import TripDetails from "./TripDetails.vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "@/components/layout/Navbar.vue";

export default {
  name: "Request",
  components: {
    ButtonBasic,
    GalleryLightbox,
    Navbar,
    RequestChanges,
    Tab,
    TripOffer,
    TripDetails,
  },
  data() {
    return {
      showGalleryLightbox: false,
      showpageSheet: false,
      photos: [],
      requestTabSections: [
        {
          //Offer
          name: this.$t("request.tabs.item_1"),
          isActive: true,
          textColor: "text-black-base",
        },
        {
          //Details
          name: this.$t("request.tabs.item_2"),
          isActive: false,
          textColor: "text-grey-dark",
        },
      ],
      btnLoading: false,
      loading: false,
      requestSheetPosition: "aside",
    };
  },
  computed: {
    ...mapGetters("user", ["offer", "tripDetails"]),
    backPath() {
      let tab = "";
      if (this.$route.query["previous_tab"] !== undefined) {
        tab = "?tab=" + this.$route.query["previous_tab"];
      }
      return `/account/trips${tab}`
    },
    showBottomNav() {
      let status = this.tripDetails.trip_request_status;
      if (status == "completed") return false;
      if (status == "draft") return false;
      if (status == "changes") return false;
      if (status == "open") return false;
      if (status == "cancelled") return false;
      return true;
    },
  }, 
  methods: {
    ...mapActions("user", ["getSingleTripRequest", "getTripAgent"]),
    goToRequest() {
      window.location = "/request";
    },
    closeLightbox() {
      this.showGalleryLightbox = !this.showGalleryLightbox;
      document.body.style.overflow = "auto";
    },
    openLightBox(object) {
      this.showGalleryLightbox = object.boolean;
      this.photos = [...object.photos];
      document.body.style.overflow = "hidden";
    },
    openSheet() {
      this.requestSheetPosition = "right-0";
      this.showpageSheet = true;
      document.body.style.overflow = "hidden";
    },
    closeSheet() {
      this.requestSheetPosition = "aside";
      this.showpageSheet = false;
      document.body.style.overflow = "auto";
    },
    openRequest() {
      this.showRequests = !this.showRequests;
      this.showRequest = !this.showRequest;
    },
    async calculateStartTime() {
      //Create a timestamp if there isn't a stamp set yet
      if (this.$store.getters["tracker/time"].booking.set === false) {
        await this.$store.dispatch("tracker/setBookingStart");
      }
    },
    async getTrip() {
      this.loading = true;
      const res = await this.getSingleTripRequest(this.$route.query.id);
      if (!res) return false;
      if (Object.keys(this.offer).length) {
        await this.getTripAgent(this.offer.trip_agencies.split(",")[0]);
      }
      this.loading = false;
    },
  },
  async created() {
    await this.getTrip();
  },
};
</script>

<style scoped>
.aside{
  @apply -right-full;
}

.pageSheet {
  @apply h-100vh fixed top-0 w-full z-30 transition-all duration-700 ease-in-out;
}

.btn-close-container {
  @apply z-50 h-10vh fixed top-0 w-full flex items-center;
}

.main-wrapper {
  @apply relative w-full transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.page-title {
  @apply mb-4 font-sans text-2xl font-bold text-black-base;
}

.wrapper {
  @apply relative w-full py-6 transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.btn-wrapper {
  @apply flex justify-between w-full;
}

.bottom-nav {
  @apply fixed px-4 bottom-0 z-10 w-full bg-white border-t border-grey-base;
}
.section-container {
  @apply px-4;
}
.wrapper {
  @apply relative transform -translate-x-1/2 left-1/2;
  @apply max-w-screen-sm w-full;
}
.navbar {
  @apply fixed top-0 z-20 w-full border-none;
}
</style>
