<template>
  <div class="sheet">
    <nav>
      <div class="nav-wrapper">
        <ButtonIcon @click.native="$emit('close')"></ButtonIcon>
      </div>
    </nav>
    <form @submit.prevent="foo" class="sheet-request-form">
      <div class="details">
        <h2 class="sheet-title">{{ $t("offer.sheet.title") }}</h2>
        <p class="text-black-lightest mb-6">
          {{ $t("offer.sheet.description") }}
        </p>
        <TextArea
          v-model="message"
          :label="$t('page.trip_request.request_change.your_message.label')"
          :placeholder="`${$t('offer.sheet.placeholder')}`"
          @blur="$v.message.$touch()"
          :class="[$v.message.$error ? 'border-red-base' : 'border-grey-dark']"
        />
        <ErrorMessage v-if="!$v.message.required && $v.message.$error" :errorMsg="$t('offer.sheet.error')"/>

        <ButtonPrimary
          class="btn-send"
          :text="$t('offer.sheet.button')"
          type="submit"
          :loading="loading"
        />
      </div>
    </form>
  </div>
</template>
<script>
import TextArea from "@/components/inputs/TextArea";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ButtonIcon from "@/components/buttons/ButtonIcon.vue";
const { required } = require("vuelidate/lib/validators");

export default {
  name: "BookingDetails",
  components: {
    TextArea,
    ErrorMessage,
    ButtonIcon
},
  props: {
    offerId: {
      type: Number,
      // required: true,
    },
  },
  data() {
    return {
      message: "",
      loading: false,
    };
  },
  validations: {
    message: {
      required,
    },
  },
  computed: {
    msgErrMsg() {
      let msg;
      if (!this.$v.message.required) {
        msg = "Message is required";
      }
      return msg;
    },
  },
  methods: {
    async foo() {
      try {
        this.$v.message.$touch();
        if (this.$v.message.$invalid) {
          return false;
        }

        this.loading = true;
        const { message } = this;
        const { id } = this.$route.query;
        const data = { message, trip_request_id: id, offer_id: this.offerId };
        const res = await this.$store.dispatch("user/requestTripChanges", data);
        if (!res) return false;
        this.loading = false;
        this.$emit("action");
        this.$notify({
          type: "success",
          title: "Your request has been sent",
        });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-send{
  @apply block px-5 mt-6 w-full;
}

.sheet {
  @apply bg-white rounded-t-md;
}

nav{
  @apply py-4 px-4;
}

.nav-wrapper{
  @apply max-w-screen-md relative left-1/2 transform -translate-x-1/2;
}
.details {
  @apply px-3 md:px-0 max-w-md;
  @apply relative left-1/2 transform -translate-x-1/2;
}

.sheet-request-form {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2;
}

.sheet-title {
  @apply font-semibold text-2xl text-black-base;
}

.destination-block {
  @apply py-4 md:px-0 px-3 flex items-center;
}

.agency-img-container {
  @apply relative overflow-hidden h-14 w-14 rounded-full mr-3;
}

.agency-img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

.dates {
  @apply flex items-center py-4 px-3 md:px-0 w-full;
}

.days-badge {
  @apply h-14 w-14 flex items-center flex-wrap justify-center bg-black-base rounded-xl mr-4;
}

.days-badge-text {
  @apply w-full text-center block text-sm font-semibold;
}
.date-block {
  @apply py-3;
}

.small-title {
  @apply text-black-lightest text-xxs font-bold uppercase block;
}

.destination-flag-container {
  @apply font-sans text-black-base font-semibold flex items-center;
}

.destination-flag {
  @apply relative overflow-hidden w-6 h-6 mr-2;
}

.flag {
  @apply h-full absolute top-0 left-0;
}

.date {
  @apply font-sans text-black-base text-lg font-semibold;
}

.row {
  @apply flex justify-between py-4;
}
</style>
