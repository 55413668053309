<template>
  <nav class="tab">
    <ul class="flex">
      <li
        @click="toggleTabItem(index)"
        v-for="(tabItem, index) in tabItems"
        :key="index"
        :class="['tab-item flex flex-wrap']"
      >
        <span
          :class="[tabItem.isActive ? 'selector-active' : 'selector-inactive']"
        >
          {{ tabItem.name }}
        </span>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "Tab",
  props: {
    tabItems: {
      type: Array,
      default: () => [
        { name: "Item 1", isActive: true },
        { name: "Item 1", isActive: false },
      ],
    },
  },
  methods: {
    toggleTabItem(itemIndex) {
      for (let [index, tabItem] of this.tabItems.entries()) {
        tabItem.isActive = itemIndex === index;
      }
    },
  },
};
</script>
<style scoped>
.tab {
  @apply inline-block border-b border-grey-base;
}
.selector-active {
  @apply border-teal-base border-b-4 flex items-center py-4 px-2;
  @apply text-sm text-teal-base;
}

.selector-inactive {
  @apply border-white text-grey-dark flex py-4 px-2 border-b-4;
  @apply text-sm;
}

.tab-item {
  @apply pr-3 sm:pr-6 cursor-pointer font-semibold block text-sm sm:text-base;
}
</style>
