<template>

  <button :type="type" class="btn-basic" :data-loading="loading">
    <i v-if="isIcon && !loading" :class="[iconClass, 'mr-2 text-xl']"></i>
    <span v-if="!loading"> {{ text }} </span>
    <span class="loader" v-else> loading </span>
  </button>
</template>

<script>
export default {
  name: "ButtonBasic",
  props: {
    isIcon: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "bx bx-log-out",
    },
    type: {
      type: String,
      default: "button"
    },
    text: {
      type: String,
      default: "button"
    },
    loading: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>
.btn-basic {
  @apply px-3 rounded-lg;
  @apply transition duration-300 ease-in-out;
  @apply inline-flex items-center justify-center;
  @apply text-black-base font-semibold text-sm;
  @apply bg-white transform border-grey-base border;
  @apply focus:scale-95 focus:outline-none focus:ring-2 focus:border-transparent focus:ring-black-light focus:ring-offset-2;
}
</style>