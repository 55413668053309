<template>
  <div class="gallery">
    <div class="gallery-container">
      <Button
          icon-class="bx bxs-image-alt"
          v-if="showCalltoAction"
          :text="`${$t('offer.gallery.button')}`"
          class="absolute left-3 bottom-3 z-10"
      />
      <figure class="gallery-main-img">
        <img
            class="absolute object-cover w-full h-full"
            :src="mainPhoto.img"
            :alt="mainPhoto.alt || 'Placeholder image'"
        />
      </figure>
      <div
          class="gallery-side-img-container"
      >
        <figure class="relative overflow-hidden rounded side-img">
          <img
              class="absolute object-cover w-full h-full"
              :src="sidePhotoTop.img"
              :alt="sidePhotoTop.alt || 'Placeholder image'"
          />
        </figure>
        <figure class="relative overflow-hidden rounded side-img">
          <img
              class="absolute object-cover w-full h-full"
              :src="sidePhotoBottom.img"
              :alt="sidePhotoBottom.alt || 'Placeholder image'"
          />
        </figure>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/buttons/Button";

export default {
  name: "Gallery",
  components: {
    Button,
  },
  props: {
    mainPhoto: {
      type: Object,
      default: () => {
        return {
          img: "placeholder.png",
          alt: "Placeholder image",
        };
      },
    },
    showCalltoAction: {
      type: Boolean,
      default: true,
    },
    sidePhotoTop: {
      type: Object,
      default: () => {
        return {
          img: "placeholder.png",
          alt: "Placeholder image",
        };
      },
    },
    sidePhotoBottom: {
      type: Object,
      default: () => {
        return {
          img: "placeholder.png",
          alt: "Placeholder image",
        };
      },
    },
  },
};
</script>
<style scoped>
.gallery-container {
  @apply relative grid grid-cols-3 w-full gap-4;
  @apply h-96 cursor-pointer;
}

.gallery-main-img {
  @apply relative col-span-3 sm:col-span-2 overflow-hidden;
  @apply sm:rounded
}

.gallery-side-img-container {
  @apply sm:grid gap-4  hidden h-full;
}
</style>
