<template>
  <section class="section" id="pricing">
    <div class="wrapper">
    <h2 class="section-title">{{ $t("offer.pricing.title") }}</h2>
    <div class="mt-2 pricing">
      <PricingBlocks 
        :adult-price="pricePerAdult"
        :child-price="pricePerChild" 
        :children="offer.trip_children" 
        :single-room-price="offer.trip_single_room_price" 
        :single-rooms="offer.trip_single_rooms"
        :currency="offer.trip_currency"
      />
      <h3 class="section-sub-title">{{ $t("offer.pricing.title") }}</h3>
      <div class="pricing-table-row mt-4">
        <div class="calculation">{{ pricePerAdult | currency(offer.trip_currency) }} × {{ adults }}</div>
        <div class="result">
          {{ totalAdultsPrice | currency(offer.trip_currency) }}
        </div>
      </div>

      <Divider class="w-full my-4"/>

      <div v-if="offer.trip_children > 0" class="pricing-table-row">
        <div class="calculation">{{ pricePerChild | currency(offer.trip_currency) }} × {{ children }}</div>
        <div class="result">{{ totalChildrenPrice | currency(offer.trip_currency) }}</div>
      </div>

      <Divider v-if="offer.trip_children > 0" class="w-full my-4"/>

      <div v-if="totalSingleRoomPrice > 0" class="pricing-table-row">
        <div class="fee">{{ pricePerSingleRoom | currency(offer.trip_currency) }} × {{ singleRooms }}</div>
        <div class="result">
          {{ totalSingleRoomPrice | currency(offer.trip_currency) }}
        </div>
      </div>

      <Divider v-if="totalSingleRoomPrice > 0" class="w-full mt-4"/>

      <div class="py-3 font-bold pricing-table-row text-black-base">
        <div class="total">{{ $t("offer.pricing.total") }}</div>
        <div class="result">{{ totalPrice | currency(offer.trip_currency) }}</div>
      </div>

      <div class="items-section-container">
        <div class="inclusions items-section">
          <h3 class="font-sans text-lg font-semibold text-black-base">
            {{ $t("offer.scope.inclusions") }}
          </h3>
          <div class="mt-2 list">
            <div
                v-for="(inclusion, index) in offer.trip_inclusions.split(',')"
                :key="index"
                class="flex items-start mb-3"
            >
              <i class="text-lg bx bxs-check-circle inclusion-icon"></i>
              <div class="ml-2 font-sans text-black-base">
                {{ inclusion }}
              </div>
            </div>
          </div>
        </div>
        <div class="exclusions items-section">
          <h3 class="font-sans text-lg font-semibold text-black-base">
            {{ $t("offer.scope.exclusions") }}
          </h3>
          <div class="mt-2 list">
            <div
                v-for="(exclusion, index) in offer.trip_exclusions.split(',')"
                :key="index"
                class="flex items-start mb-3"
            >
              <i class="text-lg bx bxs-x-circle exclusion-icon"></i>
              <div class="ml-2 font-sans text-black-base">
                {{ exclusion }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>
<script>
import Divider from "@/components/dividers/Divider";
import PricingBlocks from "../../components/PricingBlocks.vue"
export default {
  name: "PricingBlock",
  components: {
    Divider,
    PricingBlocks
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      region: "en-US",
      earningFees: 1 + this.offer.trip_earning_fees / 100,
    };
  },
  computed: {
    adults() {
      let count = this.offer.trip_adults;
      return this.$tc("offer.pricing.adults", count, {
        count: count,
      });
    },
    children() {
      let count = this.offer.trip_children;
      return this.$tc("offer.pricing.children", count, {
        count: count,
      });
    },
    singleRooms() {
      let count = this.offer.trip_single_rooms;
      return this.$tc("offer.pricing.rooms", count, {count: count});
    },
    currencyRegion() {
      let currency = this.offer.trip_currency;
      if (currency == "USD") return "en-US";
      if (currency == "CHF") return "en-CH";
      if (currency == "GBP") return "en-EN";
      return "fr-FR";
    },
    pricePerSingleRoom() {
      return this.offer.trip_single_room_price;
    },
    pricePerAdult() {
      return this.offer.trip_price_per_adult * this.earningFees;
    },
    pricePerChild() {
      return this.offer.trip_price_per_child * this.earningFees;
    },
    totalAdultsPrice() {
      return (
          this.offer.trip_price_per_adult *
          this.offer.trip_adults *
          this.earningFees
      );
    },
    totalChildrenPrice() {
      return (
          this.offer.trip_price_per_child *
          this.offer.trip_children *
          this.earningFees
      );
    },
    totalSingleRoomPrice() {
      return this.offer.trip_single_room_price * this.offer.trip_single_rooms;
    },
    totalPrice() {
      return  this.totalAdultsPrice + this.totalChildrenPrice + this.totalSingleRoomPrice;
    },
  },
};
</script>

<style scoped>
.inclusion-icon{
  @apply mt-1 text-green-base;
}

.exclusion-icon{
  @apply mt-1 text-red-base;
}
.items-section-container{
  @apply mt-12;
}

.exclusions{
  @apply mt-6;
}
.items-section{
  @apply w-full sm:w-calc-1/2;
}
.section-title {
  @apply mt-12 font-sans text-2xl font-semibold text-black-base;
}

.section-sub-title {
  @apply mt-6 font-sans text-xl font-semibold text-black-base;
}

.price-travelers {
  @apply inline-block p-4 mr-3 font-sans rounded-md bg-white shadow-sm border border-grey-base;
}

.price-per-traveler {
  @apply font-semibold text-black-base text-xl;
}

.price-label {
  @apply text-black-lightest text-base;
}

.pricing-table-row {
  @apply flex justify-between w-full text-black-base;
}

.section {
  @apply px-4 py-6;
}
.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}
</style>
