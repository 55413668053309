<template>
	<article>
    <ol class="itinerary-list">
      <li class="itinerary-list-item">
        <span class="itinerary-list-item-icon">
          <i class="bx bxs-calendar-alt"> </i>
        </span>
        <h3 class="itinerary-list-item-title">{{ title }}</h3>
		<p class="title-date">{{ date }}</p>
        <div class="tag tag--grey" v-if="isAccommodation">
          <i class="bx bxs-hotel icon"> </i>
          {{ itinerary.accommodation.name }}
        </div>
        <div class="tag tag--grey ml-1.5" v-if="isAccommodation && isMealPlan">
          <i class="bx bxs-bowl-hot icon"> </i>
          {{ mealPlan || $t("common.no_meal_plan")}}
        </div>
        <div
          v-for="item in itinerary.experiences"
          :key="item.id"
          v-show="item.name.length"
        >
          <ul class="list--item" v-if="item.type === 'text'">
            <li>{{ item.name }}</li>
          </ul>
          <div
            class="tag tag--blue"
            v-show="item.type === 'experience'"
          >
            <i class="bx bxs-star icon"> </i> {{ item.name }}
          </div>
        </div>
        <Button
        class="btn-accommodation"
          v-if="isAccommodation && isImages"
          @click.native="emitPhotos(itinerary.accommodation.image)"			
          :text="$t('offer.itinerary.button')"
          icon-class="bx bxs-image-alt"
          size="sm"
          kind="basic"
        />
      </li>
    </ol>
	</article>
</template>

<script>
import Button from "@/components/buttons/Button";
import { formatDate, locale } from "../../../../shared/dateHandler";
export default {
	name: "DayBlock",
	components: {
		Button,
	},
	methods: {
		emitPhotos(images) {
			this.$emit("showPhotos", images);
		},
		getMealInFr(meal) { 
			let meals = {
				Breakfast: "Petit-déjeuner",
				Lunch: "Déjeuner",
				Dinner: "Dîner"
			};

			if (meals[meal] !== undefined) {
				return meals[meal];
			}

			return "";
		}
	},
	computed: {
		isAccommodation() {
			let objectKeys = "accommodation" in this.itinerary ? Object.keys(this.itinerary.accommodation) : {};
			if ("accommodation" in this.itinerary && objectKeys.length > 0) {
				return true;
			}
			return false;
		},
		isMealPlan() {
			if ("mealplan" in this.itinerary.accommodation) {
				return true;
			}
			return false;
		},
		isImages() {
			if (this.itinerary.accommodation.image.length > 0) {
				return true;
			}

			return false;
		},
		mealPlan() {
			if(this.isAccommodation && this.isMealPlan){
			let meals = this.itinerary.accommodation.mealplan.split(",");
			let mealPlan = "";

			if (localStorage.getItem("lang") === "fr") {
				meals.forEach((meal, index) => {
					if (index + 1 < meals.length)
						mealPlan += `${this.getMealInFr(meal)}, `;
					else
						mealPlan += this.getMealInFr(meal);
				})
			} else {
				mealPlan = this.itinerary.accommodation.mealplan.replace(/,[s]*/g, ", ");
			}

			return mealPlan;
			}
			return "";
		},
		date() {
			return formatDate(this.itinerary.date, "ddd, DD MMM YYYY", locale);
		}
	},
	props: {
		itinerary: {
			type: Object,
			required: true,
		},
		title: {
			type: String,
			default: "Title",
		},
	},
	mounted() {
	}
}	
</script>

<style scoped>
.list--item {
	@apply text-black-base text-sm font-medium block my-2;
	@apply pl-5;
}

.list--item li{
	@apply list-item list-disc;
}

.day-title {
	@apply font-sans font-medium text-black-base;
}

.sub-title {
	@apply font-sans text-sm text-black-lighter;
}

.tag {
	@apply inline-flex items-center gap-1 pl-2 pr-2.5 py-1.5 my-1;
	@apply text-xs rounded-md font-semibold;
}

.tag--grey {
	@apply bg-grey-base bg-opacity-50 text-black-base;
}

.tag--blue {
	@apply bg-yellow-light bg-opacity-50 text-yellow-text;
}

.experience {
	@apply inline-block px-2 py-1 mt-3 mb-1 text-xs rounded-full bg-violet-lighter text-violet-text font-semibold;
}

.icon {
	@apply mr-0.5 text-base;
}

.accommodation {
	@apply flex items-start justify-between mt-6;
}

.btn-style {
	@apply border border-grey-dark shadow-sm;
}

.list-item {
	@apply font-sans list-disc text-black-base;
}
.title {
	@apply mt-12 font-sans text-2xl font-semibold;
}

.title-date{
	@apply mt-1 mb-6 font-sans text-sm text-black-lighter;
}
.itinerary-list {
	@apply relative border-l border-grey-base;
}

.itinerary-list-item {
	@apply ml-6 py-8;
}

.itinerary-list-item-icon {
	@apply inline-flex absolute -left-3 justify-center items-center w-6 h-6 bg-teal-lighter rounded-full ring-8 ring-white;
	@apply text-xs text-teal-base;
}

.itinerary-list-item-title {
	@apply block items-center text-lg font-semibold text-black-base;
}

.itinerary-list-item-date {
	@apply block mb-2 text-sm font-normal leading-none text-black-lighter;
}

.itinerary-list-item-text {
	@apply mb-3 mt-1 text-sm font-medium text-black-base;
}

.set-inline {
	@apply inline;
}
.set-inline:not(:last-child) {
	@apply mr-1.5;
}

.btn-accommodation{
  @apply mt-4 flex;
}
</style>
