<template>
	<section class="pricing-section" id="pricing">
			<div class="wrapper">
				<div class="price--items--container">
					<div class="price--item">
						<div class="price--text--container" property="description">
							<h3 class="price--title">
								<i class="bx bx-body bx-xs"> </i> {{$t("common.adults")}}
							</h3>
						</div>
						<div class="price--container" property="offers" typeof="Offer">
							<span class="price"> {{ adultPrice | currency(currency) }}</span>
							<span class="price--unit" v-t="'common.per_traveller'"/>
						</div>
					</div>
					<div class="price--item" v-if="children > 0">
						<div class="price--text--container" property="description">
							<h3 class="price--title">
								<i class="bx bx-child"> </i> {{ $t("common.children") }}
							</h3>
						</div>
						<div class="price--container" property="offers" typeof="Offer">
							<span class="price">
								{{ childPrice | currency(currency) }}
							</span>
							<span class="price--unit" v-t="'common.per_traveller'"/>
						</div>
					</div>
					<div class="price--item" v-if="singleRooms > 0">
							<div class="price--text--container" property="description">
								<h3 class="price--title">
									<i class="bx bxs-hotel bx-xs"> </i> {{ $t("page.group_tour.section.price.single_room") }}
								</h3>
							</div>
							<div class="price--container" property="offers" typeof="Offer">
								<span class="price"> {{ totalSingleRoomPrice | currency(currency) }}</span>
								<span class="price--unit" v-t="'common.per_traveller'"/>
							</div>
					</div>
				</div>
			</div>
		</section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "PricingBlock",
	data() {
		return {
			exchangeRates: {},
		};
	},
	props: {
		adultPrice: {
			type: Number,
			default: 1000,
		},
		children: {
			props: Number,
			default: 0,
		},
		childPrice: {
			type: Number,
			default: 1000,
		},
		currency: {
			type: String,
			default: "USD",
		},
		singleRooms: {
			type: Number,
			default: 1,
		},
		singleRoomPrice: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		...mapGetters({
			getCurrency: "global/currency",
			rates: "exchange/rates",
		}),
		totalSingleRoomPrice() {
			return this.singleRoomPrice * this.singleRooms;
		}
	},
};
</script>

<style scoped>

.section-title {
	@apply font-sans text-2xl font-semibold text-black-base;
}

.wrapper {
	@apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.price--items--container {
	@apply grid gap-4 sm:grid-cols-2 mt-6;
}

.price--item {
	@apply border border-grey-base rounded-lg p-4 shadow-sm;
	@apply flex items-center justify-between gap-8;
}

.price--title {
	@apply font-semibold text-black-base text-lg;
	@apply flex items-center gap-1;
}

.price--description {
	@apply text-xs text-black-lighter;
}

.price--container > span {
	@apply block;
}

.price {
	@apply font-bold text-base text-black-base text-right;
}

.price--unit {
	@apply text-black-lighter text-xs font-medium text-right;
}
</style>
